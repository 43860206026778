import React from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const DashboardCharts = ({ data }) => {
  const generalInfo = data ? data['General Information'] : [];
  const reviewAnalysis = data && data['Review Platform Analysis'] ? data['Review Platform Analysis'] : [];

  const getBarData = (labels, values, label) => {
    return {
      labels,
      datasets: [{
        label: label,
        data: values,
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1
      }]
    };
  };

  const getPieData = (labels, values) => {
    return {
      labels,
      datasets: [{
        data: values,
        backgroundColor: labels.map(() => `#${Math.floor(Math.random() * 16777215).toString(16)}`)
      }]
    };
  };

  const positiveReviews = {};
  const negativeReviews = {};

  if (reviewAnalysis.length > 0) {
  reviewAnalysis.forEach(review => {
    if (review.Type === 'Positive') {
      positiveReviews[review.Company] = (positiveReviews[review.Company] || 0) + 1;
    } else if (review.Type === 'Negative') {
      negativeReviews[review.Company] = (negativeReviews[review.Company] || 0) + 1;
    }
  });
}


  const companies = [...new Set([...Object.keys(positiveReviews), ...Object.keys(negativeReviews)])];

  const positiveValues = companies.map(company => positiveReviews[company] || 0);
  const negativeValues = companies.map(company => negativeReviews[company] || 0);

  return (
    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
      <div>
        <h2>Number of Positive and Negative Reviews per Company</h2>
        {companies.length > 0 ? (
          <>
            <Bar data={getBarData(companies, positiveValues, 'Positive Reviews')} />
            <Bar data={getBarData(companies, negativeValues, 'Negative Reviews')} />
          </>
        ) : (
          <p>No review data available.</p>
        )}
      </div>

      <div>
        <h2>Annual Recurring Revenue</h2>
        {generalInfo && generalInfo.length > 0 ? (
          <Pie data={getPieData(generalInfo.map(item => item.Company), generalInfo.map(item => item['Annual Recurring Revenue']))} />
        ) : (
          <p>No revenue data available.</p>
        )}
      </div>

      <div>
        <h2>Number of Employees per Company</h2>
        {generalInfo && generalInfo.length > 0 ? (
          <Bar data={getBarData(generalInfo.map(item => item.Company), generalInfo.map(item => item['Number of Employees']), 'Number of Employees')} />
        ) : (
          <p>No employee data available.</p>
        )}
      </div>

      <div>
        <h2>Number of LinkedIn Followers per Company</h2>
        {generalInfo && generalInfo.length > 0 ? (
          <Bar data={getBarData(generalInfo.map(item => item.Company), generalInfo.map(item => item['Number of Linkein Followers']), 'Number of LinkedIn Followers')} />
        ) : (
          <p>No LinkedIn follower data available.</p>
        )}
      </div>
    </div>
  );
};

export default DashboardCharts;
