import React, { useState } from "react";
import axios from "axios";
import { Input, Button, List, Avatar, Card, Row, Col } from "antd";
import {
  UserOutlined,
  RobotOutlined,
  BulbOutlined,
  BookOutlined,
  MessageOutlined,
  ReadOutlined,
} from "@ant-design/icons";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const { TextArea } = Input;

const AskAI = () => {
  const [messages, setMessages] = useState([]);
  const [question, setQuestion] = useState("");
  const [loading, setLoading] = useState(false);

  const predefinedQuestions = [
    {
      question:
        "What tactics have been effective or ineffective for our competitors?",
      icon: <BulbOutlined />,
    },
    {
      question:
        "Which webinar topics are most popular among competitors' audiences?",
      icon: <MessageOutlined />,
    },
    {
      question:
        "Please write a blog post that examines the advantages of choosing Localyser over Vendasta for reputation management. Highlight the strengths and unique features of Localyser, and contrast these with the limitations of Vendasta. Ensure the writing style is professional and analytical, in the manner of a McKinsey report.",
      icon: <ReadOutlined />,
    },
    {
      question:
        "What functionalities do different reputation management companies offer?",
      icon: <BookOutlined />,
    },
  ];

  const formatResponseContent = (content) => {
    // Replace source indicators with an empty string
    let formattedContent = content.replace(/【\d+:\d+†source】/g, "");

    // Replace markdown with HTML tags
    formattedContent = formattedContent
      .replace(/###\s*(.+)/g, "<h3>$1</h3>") // Convert ### headers to h3
      .replace(/\*\*(.+?)\*\*/g, "<b>$1</b>") // Convert **bold** to <b>
      .replace(/\d+\.\s*/g, "<br/><b>$&</b>") // New line before numbered lists
      .replace(/-\s*(.+)/g, "<li>$1</li>"); // Convert bullet points to list items

    // Remove any extra unwanted characters like $1
    formattedContent = formattedContent.replace(/\$1/g, "");

    // Wrap content inside paragraph tags and ensure line breaks are handled
    formattedContent = formattedContent
      .split("\n")
      .map((line) => `<p>${line.trim()}</p>`)
      .join("");

    return formattedContent;
  };

  const handleAskAI = async () => {
    if (!question) return;

    const userMessage = { role: "user", content: question };
    setMessages([...messages, userMessage]);
    setQuestion("");
    setLoading(true);

    try {
      const res = await axios.post(`${BASE_URL}/ask`, {
        messages: [...messages, userMessage],
      });
      const formattedContent = formatResponseContent(res.data.content);
      const aiMessage = { role: "assistant", content: formattedContent };
      setMessages((prevMessages) => [...prevMessages, aiMessage]);
    } catch (error) {
      const errorMessage = {
        role: "assistant",
        content: "Error querying AI. Please try again later.",
      };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    } finally {
      setLoading(false);
    }
  };

  const handlePredefinedQuestion = async (predefinedQuestion) => {
    const userMessage = { role: "user", content: predefinedQuestion };
    setMessages([...messages, userMessage]);
    setQuestion("");
    setLoading(true);

    try {
      const res = await axios.post(`${BASE_URL}/ask`, {
        messages: [...messages, userMessage],
      });
      const formattedContent = formatResponseContent(res.data.content);
      const aiMessage = { role: "assistant", content: formattedContent };
      setMessages((prevMessages) => [...prevMessages, aiMessage]);
    } catch (error) {
      const errorMessage = {
        role: "assistant",
        content: "Error querying AI. Please try again later.",
      };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card style={{ minHeight: "400px" }}>
      <List
        itemLayout="horizontal"
        dataSource={messages}
        renderItem={(message) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                <Avatar
                  icon={
                    message.role === "user" ? (
                      <UserOutlined />
                    ) : (
                      <RobotOutlined />
                    )
                  }
                />
              }
              title={message.role === "user" ? "You" : "AI"}
              description={
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    color: "black",
                  }}
                  dangerouslySetInnerHTML={{ __html: message.content }}
                />
              }
            />
          </List.Item>
        )}
      />
      {loading && <div>Loading...</div>}
      <div style={{ marginBottom: "16px" }}>
        <Row gutter={[16, 16]}>
          {predefinedQuestions.map((item, index) => (
            <Col key={index} span={6}>
              <Card
                hoverable
                onClick={() => handlePredefinedQuestion(item.question)}
                cover={
                  <div style={{ textAlign: "center", padding: "16px" }}>
                    {item.icon}
                  </div>
                }
              >
                <Card.Meta description={item.question} />
              </Card>
            </Col>
          ))}
        </Row>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <TextArea
          rows={4}
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          placeholder="Ask a question..."
          style={{ marginRight: "16px" }}
        />
        <Button type="primary" onClick={handleAskAI} loading={loading}>
          Ask
        </Button>
      </div>
    </Card>
  );
};

export default AskAI;
