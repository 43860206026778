import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Form, Input, Button, Row, Col, Card } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import logo from "../battle-cards-2.webp";

const { Content } = Layout;

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    //   e.preventDefault();
    const response = await fetch(`${BASE_URL}/api/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    });

    if (response.ok) {
      const data = await response.json();
      navigate(`/dashboard/${data.customer_ObjectId}`);
    } else {
      alert("Invalid username or password");
    }
  };

  return (
    <Layout style={{ minHeight: "100vh", backgroundColor: "#001529" }}>
      <Content
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card
          bordered={false}
          style={{
            backgroundColor: "#001529",
            color: "#fff",
            width: 400,
            textAlign: "center",
          }}
        >
          <img
            src={logo}
            alt="BattleCards Logo"
            style={{ width: "150px", marginBottom: "24px" }}
          />
          <Form onFinish={handleLogin} style={{ textAlign: "left" }}>
            <Form.Item
              name="username"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                Log in
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Content>
    </Layout>
  );
};

export default Login;
