import React, { useState, useEffect } from "react";
import {
  Layout,
  Menu,
  Table,
  Button,
  Modal,
  Spin,
  message,
  Pagination,
  Select,
} from "antd";
import * as XLSX from "xlsx";
import {
  FileOutlined,
  TeamOutlined,
  BarChartOutlined,
  CalendarOutlined,
  VideoCameraOutlined,
  SmileOutlined,
  PieChartOutlined,
  LineChartOutlined,
  DollarOutlined,
  FundOutlined,
  RobotOutlined,
  DashboardOutlined,
  SolutionOutlined,
  LinkedinOutlined,
  UserOutlined,
} from "@ant-design/icons";
import axios from "axios";
import AskAI from "./AskAI";
import DashboardCharts from "./DashboardCharts";
import logo from "../battle-cards-2.webp";
import "../styles.css";
import { Carousel, Row, Col, Card } from "antd";
import * as d3 from "d3";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
// import linkedinData from '../linkedin_data.json';
import { useNavigate, useParams } from "react-router-dom"; // Import useParams

const BASE_URL = process.env.REACT_APP_BASE_URL;

const { Option } = Select;

const isLightColor = (color) => {
  if (!color) return true;
  const hex = color.replace("#", "");
  if (hex.length !== 6) return true;
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
  return hsp > 127.5;
};

const generateSubCardColor = (index) => {
  const colors = [
    "#ffcccc",
    "#ccffcc",
    "#ccccff",
    "#ffffcc",
    "#ccffff",
    "#ffccff",
  ];
  return colors[index % colors.length];
};

const { Header, Content, Sider } = Layout;

const ITEMS_PER_PAGE = 10; // Set the number of items per page

const toInitcap = (str) => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
};

const CustomPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <button className="custom-prev-arrow" onClick={onClick}>
      Prev
    </button>
  );
};

const CustomNextArrow = (props) => {
  const { onClick } = props;
  return (
    <button className="custom-next-arrow" onClick={onClick}>
      Next
    </button>
  );
};

const menuToSheetMapping = {
  "General Information": "General Information",
  News: "News",
  Linkedin: "Linkedin Post Analysis",
  Functionality: "Functionality",
  Events: "Online-Offline-Events-Webinars",
  "Video Analysis": "Video Analysis",
  "Employee Feedback": "Employee Feedback",
  Reviews: "Review Platform Analysis",
  Strategy: "Review Platform Strategy",
  "Google Ads": "GoogleAdsAnalysis",
  Pricing: "Historic Pricing Analysis",
  "Ask AI": "Ask AI",
  "Dashboard Charts": "Dashboard Charts",
  Jobs: "OpenPositions",
  Clients: "Clients",
};

const sectionColors = {
  "General Information": "#1e90ff",
  News: "#ff7f50",
  "Linkedin Post Analysis": "#3cb371",
  Functionality: "#dda0dd",
  "Online-Offline-Events-Webinars": "#ff6347",
  "Video Analysis": "#4682b4",
  "Employee Feedback": "#ff69b4",
  "Review Platform Analysis": "#8a2be2",
  "Review Platform Strategy": "#5f9ea0",
  "Historic Pricing Analysis": "#7b68ee",
  Clients: "#6b8e23",
};

const parseCSVFiles = async () => {
  const files = [
    "Chatmeter_analysis.csv",
    "Gatherup_analysis.csv",
    "Localyser_analysis.csv",
    "Ovation_analysis.csv",
    "Partoo_analysis.csv",
    "Reviewshake_analysis.csv",
    "Reviewtrackers_analysis.csv",
    "Synup_analysis.csv",
    "Vendasta_analysis.csv",
  ];

  const data = await Promise.all(
    files.map(async (file) => {
      const response = await fetch(`/${file}`);
      const text = await response.text();
      const parsed = d3.csvParse(text);
      return { company: file.split("_")[0], data: parsed };
    })
  );

  return data;
};

const Dashboard = () => {
  const [data, setData] = useState({});
  const [selectedMenu, setSelectedMenu] = useState("Dashboard Charts");
  const [aiResponse, setAIResponse] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [companyNames, setCompanyNames] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const carouselRef = React.useRef(null);
  const [paginatedData, setPaginatedData] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState(["All"]);
  const [current, setCurrent] = useState(1); // Default to page 1
  const [events, setEvents] = useState([]); // Add this line
  const { customer_ObjectId } = useParams(); // Extract customer_ObjectId from the route parameters
  const [functionality, setFunctionality] = useState([]); // for functionality
  const navigate = useNavigate();
  const objectId = sessionStorage.getItem("objectId"); // Use the objectId to validate the session.
  const [news, setNews] = useState([]); // For News tab data
  const [linkedin, setLinkedin] = useState([]);
  const [video_analysis, setVideoAnalysis] = useState([]);
  const [employeeFeedbacks, setEmployeeFeedbacks] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [general_informations, setGeneralInformations] = useState([]);
  const [googleAds, setGoogleAds] = useState([]);
  const [pricing, setPricing] = useState([]);

  const handleCompanyFilterChange = (selectedCompanies) => {
    setFilteredCompanies(selectedCompanies);
    let newFilteredData;

    if (selectedCompanies.includes("All")) {
      newFilteredData = filteredData; // Use the fetched LinkedIn data
    } else {
      newFilteredData = filteredData.filter((post) =>
        selectedCompanies.includes(post.company)
      );
    }

    setFilteredData(newFilteredData);
    setPaginatedData(newFilteredData.slice(0, 5)); // Set the first page of data
  };

  /*useEffect(() => {
    if (!objectId) {
      navigate('/path')
    }
  }, [objectId, navigate]);*/

  useEffect(() => {
    if (selectedMenu === "Events") {
      axios
        .get(`${BASE_URL}/api/events`)
        .then((response) => {
          setEvents(response.data);
        })
        .catch((error) => {
          console.error("There was an error fetching the events!", error);
        });
    }
  }, [selectedMenu]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch customer data
        const customerResponse = await axios.get(
          `${BASE_URL}/api/customers/${customer_ObjectId}`
        );
        const customerData = customerResponse.data;

        // Fetch events using the events ObjectIds
        const eventsResponse = await axios.post(`${BASE_URL}/api/eventsByIds`, {
          ids: customerData.events,
        });
        setEvents(eventsResponse.data);

        // Fetch functionalities using the functionalities ObjectIds
        const functionalitiesResponse = await axios.post(
          `${BASE_URL}/api/functionalitiesByIds`,
          {
            ids: customerData.functionalities,
          }
        );
        setFunctionality(functionalitiesResponse.data);
        console.log("Functionality Data:", functionalitiesResponse.data); // Ensure these are not null
        setPaginatedData(functionalitiesResponse.data.slice(0, 5)); // utkunew
        setFilteredData(functionalitiesResponse.data); // utkunew
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [customer_ObjectId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch customer data
        const customerResponse = await axios.get(
          `${BASE_URL}/api/customers/${customer_ObjectId}`
        );
        const customerData = customerResponse.data;

        // Fetch LinkedIn posts using the linkedin_posts ObjectIds
        const linkedinPostsResponse = await axios.post(
          `${BASE_URL}/api/linkedinPostsByIds`,
          {
            ids: customerData.linkedin_posts,
          }
        );
        setLinkedin(linkedinPostsResponse.data);

        setPaginatedData(linkedinPostsResponse.data.slice(0, 5));
        setFilteredData(linkedinPostsResponse.data);
        setCurrent(1);
      } catch (error) {
        console.error("Error fetching LinkedIn posts:", error);
      }
    };

    if (selectedMenu === "Linkedin") {
      fetchData();
    }
  }, [selectedMenu, customer_ObjectId]);

  useEffect(() => {
    fetch("/BattleCards - Reputation Management Software Market.xlsx")
      .then((response) => response.arrayBuffer())
      .then((buffer) => {
        const workbook = XLSX.read(buffer, { type: "array" });
        const sheets = {};
        workbook.SheetNames.forEach((sheetName) => {
          console.log("Loading sheet:", sheetName);
          const sheet = workbook.Sheets[sheetName];
          sheets[sheetName] = XLSX.utils.sheet_to_json(sheet).map((row) => {
            if (row.__EMPTY) {
              row.Company = row.__EMPTY;
              delete row.__EMPTY;
            }
            return row;
          });
        });
        console.log("Data loaded:", sheets);
        setData(sheets);
        setFilteredData(sheets[menuToSheetMapping[selectedMenu]]);
        setCompanyNames(
          sheets["General Information"].map((row) => row.Company.toLowerCase())
        );

        const loadData = async () => {
          const data_csv = await parseCSVFiles();
          console.log("CSV Data loaded:", data_csv);
          setCompanyData(data_csv);
        };
        loadData();
      })
      .catch((error) =>
        console.error("Error fetching and parsing Excel file:", error)
      );
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch customer data
        const customerResponse = await axios.get(
          `${BASE_URL}/api/customers/${customer_ObjectId}`
        );
        const customerData = customerResponse.data;

        // Fetch general informations using the general_informations ObjectIds
        const generalInformationsResponse = await axios.post(
          `${BASE_URL}/api/generalInformationsByIds`,
          {
            ids: customerData.general_informations,
          }
        );
        setGeneralInformations(generalInformationsResponse.data);
        console.log("general_informations set to", general_informations);
        setPaginatedData(generalInformationsResponse.data.slice(0, 5));
        setFilteredData(generalInformationsResponse.data);
        setCurrent(1);
      } catch (error) {
        console.error("Error fetching general information:", error);
      }
    };

    if (selectedMenu === "General Information") {
      fetchData();
    }
  }, [selectedMenu, customer_ObjectId]);

  useEffect(() => {
    const fetchNewsData = async () => {
      try {
        const customerResponse = await axios.get(
          `${BASE_URL}/api/customers/${customer_ObjectId}`
        );
        const customerData = customerResponse.data;

        // Fetch news using the news ObjectIds
        const newsResponse = await axios.post(`${BASE_URL}/api/newsByIds`, {
          ids: customerData.news,
        });
        setNews(newsResponse.data);
      } catch (error) {
        console.error("Error fetching news data:", error);
      }
    };

    if (selectedMenu === "News") {
      fetchNewsData();
    }
  }, [selectedMenu, customer_ObjectId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch customer data
        const customerResponse = await axios.get(
          `${BASE_URL}/api/customers/${customer_ObjectId}`
        );
        const customerData = customerResponse.data;

        // Fetch Video Analysis data using the video_analysis ObjectIds
        const videoAnalysisResponse = await axios.post(
          `${BASE_URL}/api/videoAnalysisByIds`,
          {
            ids: customerData.video_analysis,
          }
        );
        setVideoAnalysis(videoAnalysisResponse.data);

        setPaginatedData(videoAnalysisResponse.data.slice(0, 10));
        setFilteredData(videoAnalysisResponse.data);
        console.log("Video Analysis Data:", videoAnalysisResponse.data); // Ensure these are not null

        setCurrent(1);
      } catch (error) {
        console.error("Error fetching Video Analysis data:", error);
      }
    };

    if (selectedMenu === "Video Analysis") {
      fetchData();
    }
  }, [selectedMenu, customer_ObjectId]);

  useEffect(() => {
    const fetchEmployeeFeedbackData = async () => {
      try {
        // Fetch customer data
        const customerResponse = await axios.get(
          `${BASE_URL}/api/customers/${customer_ObjectId}`
        );
        const customerData = customerResponse.data;

        // Fetch Employee Feedbacks using the employee_feedbacks ObjectIds
        const employeeFeedbackResponse = await axios.post(
          `${BASE_URL}/api/employeeFeedbacksByIds`,
          {
            ids: customerData.employee_feedbacks,
          }
        );
        setEmployeeFeedbacks(employeeFeedbackResponse.data);

        // Initialize pagination data for the first page
        setPaginatedData(
          employeeFeedbackResponse.data.slice(0, ITEMS_PER_PAGE)
        );
        setFilteredData(employeeFeedbackResponse.data);

        setCurrentPage(1); // Start on page 1
      } catch (error) {
        console.error("Error fetching Employee Feedback data:", error);
      }
    };

    if (selectedMenu === "Employee Feedback") {
      fetchEmployeeFeedbackData();
    }
  }, [selectedMenu, customer_ObjectId]);

  useEffect(() => {
    const fetchReviewsData = async () => {
      try {
        console.log("Fetching customer data for ID:", customer_ObjectId);

        // Fetch customer data
        const customerResponse = await axios.get(
          `${BASE_URL}/api/customers/${customer_ObjectId}`
        );
        console.log("Customer data received:", customerResponse.data);
        const customerData = customerResponse.data;

        // Check if the customer has associated reviews
        if (!customerData.reviews || customerData.reviews.length === 0) {
          console.warn("No reviews found for this customer.");
          return;
        }

        // Fetch Reviews using the reviews ObjectIds
        console.log("Fetching reviews with IDs:", customerData.reviews);
        const reviewsResponse = await axios.post(
          `${BASE_URL}/api/reviewsByIds`,
          {
            ids: customerData.reviews,
          }
        );

        console.log("Reviews data received:", reviewsResponse.data);
        setReviews(reviewsResponse.data);

        setFilteredData(reviewsResponse.data);
        setPaginatedData(reviewsResponse.data.slice(0, ITEMS_PER_PAGE));
        setCurrentPage(1); // Start on page 1
      } catch (error) {
        console.error("Error fetching Reviews data:", error);
      }
    };

    if (selectedMenu === "Reviews") {
      console.log("Reviews menu selected, initiating data fetch.");
      fetchReviewsData();
    }
  }, [selectedMenu, customer_ObjectId]);

  useEffect(() => {
    const fetchGoogleAdsData = async () => {
      try {
        const customerResponse = await axios.get(
          `${BASE_URL}/api/customers/${customer_ObjectId}`
        );
        const customerData = customerResponse.data;

        const googleAdsResponse = await axios.post(
          `${BASE_URL}/api/googleAdsByIds`,
          {
            ids: customerData.google_ads,
          }
        );
        console.log("Google Ads Data", googleAdsResponse.data);
        setGoogleAds(googleAdsResponse.data); // Update googleAds state
        setFilteredData(googleAdsResponse.data);
        setPaginatedData(googleAdsResponse.data.slice(0, ITEMS_PER_PAGE));
        setCurrentPage(1);
      } catch (error) {
        console.error("Error fetching Google Ads data:", error);
      }
    };

    if (selectedMenu === "Google Ads") {
      fetchGoogleAdsData();
    }
  }, [selectedMenu, customer_ObjectId]);
  
  useEffect(() => {
  const fetchPricingData = async () => {
    try {
      const customerResponse = await axios.get(`${BASE_URL}/api/customers/${customer_ObjectId}`);
      const customerData = customerResponse.data;

      const pricingResponse = await axios.post(`${BASE_URL}/api/pricingByIds`, {
        ids: customerData.prices
      });

      setPricing(pricingResponse.data); // Update pricing state
      setFilteredData(pricingResponse.data);
      setPaginatedData(pricingResponse.data.slice(0, ITEMS_PER_PAGE));
      setCurrentPage(1); // Start on page 1

    } catch (error) {
      console.error('Error fetching Pricing data:', error);
    }
  };
  

  if (selectedMenu === 'Pricing') {
    fetchPricingData();
  }
}, [selectedMenu, customer_ObjectId]);
  
  const handleMenuSelection = ({ key }) => {
  setSelectedMenu(key);

  if (['News', 'LinkedIn', 'Functionality', 'Events', 'Video Analysis', 'Employee Feedback', 'Reviews', 'General Information', 'Google Ads', 'Pricing'].includes(key)) {
    // Use the state that holds the MongoDB data
    switch (key) {
      case 'News':
        setFilteredData(news);
        break;
      case 'LinkedIn':
        setFilteredData(linkedin);
        break;
      case 'Functionality':
        setFilteredData(functionality);
        break;
      case 'Events':
        setFilteredData(events);
        break;
      case 'Video Analysis':
        setFilteredData(video_analysis);
        break;
      case 'Employee Feedback':
        setFilteredData(employeeFeedbacks);
        break;
      case 'Reviews':
        setFilteredData(reviews);
        break;
      case 'General Information':
        setFilteredData(general_informations);
        break;
      case 'Google Ads':
        setFilteredData(googleAds);
        break;
      case 'Pricing':
        setFilteredData(pricing);
        break;
      default:
        break;
    }
  } else {
    // Fall back to Excel data
    setFilteredData(data[menuToSheetMapping[key]]);
  }
};
  const handleTableChange = (pagination, filters, sorter) => {
    let currentData;
    console.log("Selected Menu:", selectedMenu);

    if (
      [
        "News",
        "LinkedIn",
        "Functionality",
        "Events",
        "Video Analysis",
        "Employee Feedback",
        "Reviews",
        "General Information",
        "Google Ads",
        "Pricing"
      ].includes(selectedMenu)
    ) {
      // Use the corresponding MongoDB data state
      switch (selectedMenu) {
        case "News":
          currentData = news;
          break;
        case "LinkedIn":
          currentData = linkedin;
          break;
        case "Functionality":
          currentData = functionality;
          break;
        case "Events":
          currentData = events;
          break;
        case "Video Analysis":
          currentData = video_analysis;
          break;
        case "Employee Feedback":
          currentData = employeeFeedbacks;
          break;
        case "Reviews":
          currentData = reviews;
          console.log("Reviews Menu Selected Data is:", currentData);
          break;
        case "General Information":
          currentData = general_informations;
          console.log(
            "General Information assigned to filtered data on table change:",
            currentData
          );
          break;
        case "Google Ads": // Add this case for Google Ads
          currentData = googleAds;
          console.log("Google Ads Menu Selected Data is:", currentData);
          break;
        case "Pricing": // Add this case for Google Ads
          currentData = pricing;
          console.log("Pricing Menu Selected Data is:", currentData);
          break;
        default:
          break;
          
      }
    } else {
      // Fall back to Excel data
      currentData = data[menuToSheetMapping[selectedMenu]];
    }

    let filtered = currentData;

    console.log("Filters applied:", filters);
    console.log("Current data before filtering:", currentData);

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtered = filtered.filter((item) =>
          filters[key].some(
            (filterValue) =>
              filterValue.toLowerCase() === String(item[key]).toLowerCase()
          )
        );
      }
    });

    console.log("Filtered data after applying filters:", filtered);

    setFilteredData(filtered);
  };

  const [currentPage, setCurrentPage] = useState(1);

  const renderEvents = () => {
    const paginatedEvents = events.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE
    );

    return (
      <>
        {paginatedEvents.map((event, index) => {
          const isOddRow = Math.floor(index / 2) % 2 === 0; // Determine odd or even row
          const backgroundColor =
            index % 2 === 0
              ? isOddRow
                ? "#1A1A2E" // Black for first column in odd rows
                : "#0F3460" // Blue for first column in even rows
              : isOddRow
              ? "#0F3460" // Blue for second column in odd rows
              : "#1A1A2E"; // Black for second column in even rows

          return (
            <Col span={12} key={index} style={{ marginBottom: "16px" }}>
              <Card
                bordered={false}
                style={{
                  backgroundColor: backgroundColor,
                  color: "#FFFFFF",
                  minHeight: "220px",
                  fontSize: "18px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  fontFamily: "Montserrat, sans-serif",
                }}
                bodyStyle={{ padding: "20px" }}
              >
                <div style={{ textAlign: "left" }}>
                  <p
                    style={{
                      fontWeight: "bold",
                      textTransform: "capitalize",
                      marginBottom: "10px",
                    }}
                  >
                    {event.company}
                  </p>
                  <p style={{ marginBottom: "10px" }}>{event.insight}</p>
                  <a
                    href={event.url_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#F0F0F0",
                      fontSize: "18px",
                      textDecoration: "underline",
                    }}
                  >
                    Read more
                  </a>
                </div>
              </Card>
            </Col>
          );
        })}
        <Pagination
          current={currentPage}
          pageSize={ITEMS_PER_PAGE}
          total={events.length}
          onChange={(page) => setCurrentPage(page)}
          style={{ marginTop: "16px", textAlign: "center" }}
        />
      </>
    );
  };

  const renderFunctionality = () => {
    const paginatedFunctionality = functionality.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE
    );

    return (
      <>
        {paginatedFunctionality.map((item, index) => {
          const isOddRow = Math.floor(index / 2) % 2 === 0;
          const backgroundColor =
            index % 2 === 0
              ? isOddRow
                ? "#1A1A2E"
                : "#0F3460"
              : isOddRow
              ? "#0F3460"
              : "#1A1A2E";

          return (
            <Col span={12} key={index} style={{ marginBottom: "16px" }}>
              <Card
                bordered={false}
                style={{
                  backgroundColor: backgroundColor,
                  color: "#FFFFFF",
                  minHeight: "220px",
                  fontSize: "18px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  fontFamily: "Montserrat, sans-serif",
                }}
                bodyStyle={{ padding: "20px" }}
              >
                <div style={{ textAlign: "left" }}>
                  <p
                    style={{
                      fontWeight: "bold",
                      textTransform: "capitalize",
                      marginBottom: "10px",
                    }}
                  >
                    {item.company}
                  </p>
                  <p style={{ marginBottom: "10px" }}>{item.insight}</p>
                  <a
                    href={item.url_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#F0F0F0",
                      fontSize: "18px",
                      textDecoration: "underline",
                    }}
                  >
                    Read more
                  </a>
                </div>
              </Card>
            </Col>
          );
        })}
        <Pagination
          current={currentPage}
          pageSize={ITEMS_PER_PAGE}
          total={functionality.length}
          onChange={(page) => setCurrentPage(page)}
          style={{ marginTop: "16px", textAlign: "center" }}
        />
      </>
    );
  };

  const renderNews = () => {
    const paginatedNews = news.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE
    );

    return paginatedNews.map((item, index) => {
      const isOddRow = Math.floor(index / 2) % 2 === 0;
      const backgroundColor =
        index % 2 === 0
          ? isOddRow
            ? "#1A1A2E"
            : "#0F3460"
          : isOddRow
          ? "#0F3460"
          : "#1A1A2E";

      return (
        <Col span={12} key={index} style={{ marginBottom: "16px" }}>
          <Card
            bordered={false}
            style={{
              backgroundColor: backgroundColor,
              color: "#FFFFFF",
              minHeight: "220px",
              fontSize: "18px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              fontFamily: "Montserrat, sans-serif",
            }}
            bodyStyle={{ padding: "20px" }}
          >
            <div style={{ textAlign: "left" }}>
              <p
                style={{
                  fontWeight: "bold",
                  textTransform: "capitalize",
                  marginBottom: "10px",
                }}
              >
                <a
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#F0F0F0",
                    fontSize: "18px",
                    textDecoration: "underline",
                  }}
                >
                  {item.title}
                </a>
              </p>
              <p style={{ marginBottom: "10px" }}>{item.description}</p>
              <p style={{ color: "#B0B0B0", fontSize: "14px" }}>
                {new Date(item.date).toLocaleDateString()}
              </p>
            </div>
          </Card>
        </Col>
      );
    });
  };

  const renderLinkedinPosts = () => {
    return paginatedData.map((post, index) => (
      <Card key={index} style={{ marginBottom: 16 }}>
        <div style={{ fontSize: "18px", fontWeight: "bold" }}>
          <strong>{post.company}</strong> • {post.time} •{" "}
          <a href={post.url} target="_blank" rel="noopener noreferrer">
            See Original Post
          </a>
        </div>
        <div style={{ fontSize: "16px" }}>{post.text}</div>
        {post.image_url && (
          <img
            src={post.image_url}
            alt="Post Image"
            style={{ width: "100%", marginTop: 16 }}
          />
        )}
        {post.video_url && (
          <video width="100%" controls style={{ marginTop: 16 }}>
            <source src={post.video_url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
        <div style={{ marginTop: 16 }}>
          {post.num_reactions && <span>{post.num_reactions} Reactions</span>}
          {post.num_comments && <span> • {post.num_comments}</span>}
        </div>
      </Card>
    ));
  };

  const renderVideoAnalysis = () => {
    return paginatedData.map((item, index) => (
      <Card key={index} style={{ marginBottom: 16 }}>
        <div style={{ fontSize: "18px", fontWeight: "bold" }}>
          <strong>{item.company}</strong> • {item.aspect}
        </div>
        <div style={{ fontSize: "16px" }}>{item.details}</div>
        {item.video && <div style={{ marginTop: 16 }}>Video: {item.video}</div>}
      </Card>
    ));
  };

  const renderEmployeeFeedbacks = () => {
    const paginatedFeedbacks = employeeFeedbacks.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE
    );

    return (
      <>
        {paginatedFeedbacks.map((feedback, index) => {
          const isOddRow = Math.floor(index / 2) % 2 === 0;
          const backgroundColor =
            index % 2 === 0
              ? isOddRow
                ? "#1A1A2E"
                : "#0F3460"
              : isOddRow
              ? "#0F3460"
              : "#1A1A2E";

          return (
            <Col span={12} key={index} style={{ marginBottom: "16px" }}>
              <Card
                bordered={false}
                style={{
                  backgroundColor: backgroundColor,
                  color: "#FFFFFF",
                  minHeight: "220px",
                  fontSize: "18px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  fontFamily: "Montserrat, sans-serif",
                }}
                bodyStyle={{ padding: "20px" }}
              >
                <div style={{ textAlign: "left" }}>
                  <p
                    style={{
                      fontWeight: "bold",
                      textTransform: "capitalize",
                      marginBottom: "10px",
                    }}
                  >
                    {feedback.company}
                  </p>
                  <p style={{ fontWeight: "bold", marginBottom: "10px" }}>
                    <a
                      href={feedback.review_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "#F0F0F0",
                        fontSize: "18px",
                        textDecoration: "underline",
                      }}
                    >
                      {feedback.review_title}
                    </a>
                  </p>
                  <p style={{ marginBottom: "10px" }}>
                    {feedback.employee_feedback}
                  </p>
                  <p style={{ color: "#B0B0B0", fontSize: "14px" }}>
                    {new Date(feedback.review_date).toLocaleDateString()}
                  </p>
                  <p
                    style={{
                      fontWeight: "bold",
                      textTransform: "capitalize",
                      marginBottom: "10px",
                    }}
                  >
                    {feedback.feedback_type} - {feedback.rating} Stars
                  </p>
                </div>
              </Card>
            </Col>
          );
        })}
        <Pagination
          current={currentPage}
          pageSize={ITEMS_PER_PAGE}
          total={employeeFeedbacks.length}
          onChange={(page) => {
            setCurrentPage(page);
          }}
          style={{ marginTop: "16px", textAlign: "center" }}
        />
      </>
    );
  };

  const renderReviews = () => {
    const columns = getColumns(); // Ensure getColumns is called and returns the correct columns

    console.log("Columns for Reviews:", columns); // Add a log to check the columns

    return (
      <Table
        dataSource={paginatedData} // Ensure this has the correct data
        columns={columns}
        rowKey={(record, index) => index}
        onChange={handleTableChange}
        pagination={false}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light-reviews" : "table-row-dark-reviews"
        }
      />
    );
  };

  const renderGoogleAds = () => {
    return (
      <Row gutter={[16, 16]} align="stretch">
        {" "}
        {/* Ensure equal height in the row */}
        {paginatedData.map((ad, index) => {
          // Determine if the index is odd or even for alternating colors
          const isOddRow = Math.floor(index / 3) % 2 === 0; // Assuming 3 cards per row
          const backgroundColor =
            index % 2 === 0
              ? isOddRow
                ? "#1A1A2E"
                : "#0F3460"
              : isOddRow
              ? "#0F3460"
              : "#1A1A2E";

          return (
            <Col span={8} key={index} style={{ display: "flex" }}>
              {" "}
              {/* Ensure full height */}
              <Card
                bordered={false}
                hoverable
                style={{
                  flex: "1", // Allow the card to stretch
                  backgroundColor: backgroundColor,
                  color: "#FFFFFF",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  fontFamily: "Montserrat, sans-serif",
                }}
                bodyStyle={{
                  padding: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }} // Ensuring full height and flex layout
                title={
                  <span
                    style={{
                      color: "#FFFFFF",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {ad.company}
                  </span>
                }
              >
                <div style={{ flexGrow: 1 }}>
                  <p>
                    <strong>Headline:</strong> {ad.competitor_ads_headline}
                  </p>
                  <p>
                    <strong>Ad Details:</strong> {ad.ad_details}
                  </p>
                  <p>
                    <strong>Competitor:</strong>{" "}
                    {ad.competitor_placing_ad_against_the_company}
                  </p>
                </div>
                <a
                  href={ad.competitor_website}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#F0F0F0",
                    fontSize: "18px",
                    textDecoration: "underline",
                  }}
                >
                  Visit Competitor Website
                </a>
              </Card>
            </Col>
          );
        })}
      </Row>
    );
  };
  
  const renderPricing = () => {
  return (
    <Row gutter={[16, 16]} align="stretch"> {/* Ensure equal height in the row */}
      {paginatedData.map((price, index) => {
        // Determine if the index is odd or even for alternating colors
        const isOddRow = Math.floor(index / 3) % 2 === 0;  // Assuming 3 cards per row
        const backgroundColor =
          index % 2 === 0
            ? isOddRow
              ? '#1A1A2E'
              : '#0F3460'
            : isOddRow
            ? '#0F3460'
            : '#1A1A2E';

        return (
          <Col span={8} key={index} style={{ display: 'flex' }}> {/* Ensure full height */}
            <Card
              bordered={false}
              hoverable
              style={{
                flex: '1', // Allow the card to stretch
                backgroundColor: backgroundColor,
                color: '#FFFFFF',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                fontFamily: 'Montserrat, sans-serif',
              }}
              bodyStyle={{ padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }} // Ensuring full height and flex layout
              title={
                <span style={{ color: '#FFFFFF', fontSize: '18px', fontWeight: 'bold' }}>
                  {price.company}
                </span>
              }
            >
              <div style={{ flexGrow: 1 }}>
                <p>{price.analysis}</p>
              </div>
            </Card>
          </Col>
        );
      })}
    </Row>
  );
};


  const renderCompanyFilter = () => {
    return (
      <Select
        mode="multiple"
        placeholder="Filter by company"
        style={{ width: "100%", marginBottom: "16px" }}
        onChange={handleCompanyFilterChange}
        defaultValue={["All"]}
      >
        <Option value="All">All</Option>
        {[...new Set(filteredData.map((post) => post.company))].map(
          (company) => (
            <Option key={company} value={company}>
              {company}
            </Option>
          )
        )}
      </Select>
    );
  };

  const renderSection = (section) => {
    const cleanAnalysis = section.analysis.replace(/-\s*/g, "");
    const splitByCompany = (text, companies) => {
      let splitText = text;
      companies.forEach((company) => {
        const companyNamePattern = `\\.\\s*${company}`;
        const regex = new RegExp(companyNamePattern, "gi");
        splitText = splitText.replace(regex, `.|||${company}`);
      });
      return splitText.split(".|||");
    };

    const sentences = splitByCompany(cleanAnalysis, companyNames);
    const mainColor = sectionColors[section.tab] || "#ffffff";
    const isLight = isLightColor(mainColor);

    let subCards = [];
    let currentSubCard = "";

    sentences.forEach((sentence, index) => {
      let formattedSentence = sentence.trim();
      companyNames.forEach((company) => {
        const companyRegex = new RegExp(`\\b${company}\\b`, "gi");
        formattedSentence = formattedSentence.replace(
          companyRegex,
          `<b>${
            company.charAt(0).toUpperCase() + company.slice(1).toLowerCase()
          }</b>`
        );
      });

      const startsWithCompany = companyNames.some((company) =>
        formattedSentence.startsWith(
          `<b>${
            company.charAt(0).toUpperCase() + company.slice(1).toLowerCase()
          }</b>`
        )
      );

      if (startsWithCompany && currentSubCard) {
        subCards.push(currentSubCard);
        currentSubCard = formattedSentence;
      } else {
        currentSubCard += (currentSubCard ? ". " : "") + formattedSentence;
      }
    });

    if (currentSubCard) {
      subCards.push(currentSubCard);
    }

    return (
      <Card
        title={section.tab}
        bordered={false}
        style={{
          backgroundColor: mainColor,
          color: isLight ? "black" : "white",
        }}
        bodyStyle={{ color: isLight ? "black" : "white" }}
      >
        {subCards.map((subCard, index) => {
          const subCardColor = generateSubCardColor(index);
          const isSubCardLight = isLightColor(subCardColor);
          const textColor = isSubCardLight ? "black" : "white";

          return (
            <Card
              key={index}
              bordered={false}
              style={{
                backgroundColor: subCardColor,
                marginBottom: "8px",
                color: textColor,
              }}
              bodyStyle={{ color: textColor }}
            >
              <span dangerouslySetInnerHTML={{ __html: subCard }} />
            </Card>
          );
        })}
      </Card>
    );
  };

  const renderSlide = (company) => {
    console.log("Rendering slide for company:", company.company);
    return (
      <div key={company.company}>
        <h2>
          {company.company.charAt(0).toUpperCase() + company.company.slice(1)}
        </h2>
        <Row gutter={[16, 16]}>
          <Col span={12} style={{ marginBottom: "24px" }}>
            {renderSection(company.data[0])}
          </Col>
          <Col span={12} style={{ marginBottom: "24px" }}>
            {renderSection(company.data[1])}
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          {company.data.slice(2, 5).map((section, index) => (
            <Col span={8} key={index} style={{ marginBottom: "24px" }}>
              {renderSection(section)}
            </Col>
          ))}
        </Row>
        <Row gutter={[16, 16]}>
          {company.data.slice(5, 8).map((section, index) => (
            <Col span={8} key={index} style={{ marginBottom: "24px" }}>
              {renderSection(section)}
            </Col>
          ))}
        </Row>
        <Row gutter={[16, 16]}>
          {company.data.slice(8).map((section, index) => (
            <Col span={8} key={index} style={{ marginBottom: "24px" }}>
              {renderSection(section)}
            </Col>
          ))}
        </Row>
      </div>
    );
  };

  const formatAIResponse = (response) => {
    // Additional: Regex to replace **text** with <b>text</b>
    const formattedResponse = response.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");

    // Original: Split response by lines and apply formatting logic
    const finalResponse = formattedResponse
      .split("\n")
      .filter((text) => text.trim() !== "")
      .map((text, index) => {
        let formattedText = text.replace(/(\d+)/g, "<b>$1</b>"); // Keep existing logic to bold numbers
        companyNames.forEach((company) => {
          const companyRegex = new RegExp(`\\b${company}\\b`, "gi");
          formattedText = formattedText.replace(
            companyRegex,
            `<b>${
              company.charAt(0).toUpperCase() + company.slice(1).toLowerCase()
            }</b>`
          );
        });

        // Keep original logic to wrap text with paragraphs or list items
        if (text.includes(":")) {
          return `<p><b>${formattedText.replace(/^-\s*/, "")}</b></p>`;
        }
        return `<li>${formattedText.replace(/^-\s*/, "")}</li>`;
      })
      .join("");

    // Keep original logic to wrap final response in a list
    return `<ul>${finalResponse}</ul>`;
  };

  const handleAIAnalysis = async () => {
    try {
      setIsLoading(true);

      console.log("AI Analysis triggered for sheet:", selectedMenu);

      let response;
      if (
        [
          "Employee Feedback",
          "Video Analysis",
          "Events",
          "Functionality",
          "LinkedIn",
          "News",
          "General Information",
        ].includes(selectedMenu)
      ) {
        const dataToSend = filteredData; // Send full data directly

        response = await axios.post(`${BASE_URL}/analyze-data`, {
          prompt: `Provide insights and key takeaways for the ${selectedMenu} data.`,
          dataType: selectedMenu.toLowerCase(),
          data: dataToSend,
        });
      } else if (selectedMenu === "Dashboard Charts") {
        const chartsData = {
          generalInfo: data["General Information"],
          reviewAnalysis: data["Review Platform Analysis"],
        };

        response = await axios.post(`${BASE_URL}/ask-dashboard-charts`, {
          prompt: `Provide insights and key takeaways for the data presented in the dashboard charts.`,
          chartsData: chartsData,
        });
      } else {
        response = await axios.post(`${BASE_URL}/ask-excel`, {
          prompt: `You are a management consultant who wants to keep the CEO abreast of events in his industry. You will review the given data provided for each element you will give the salient points of the post and also confirm what the key takeaways are likely to be and possible people who also need to be informed of this.`,
          tabName: menuToSheetMapping[selectedMenu],
          filteredData:
            filteredData.length > 0
              ? filteredData
              : data[menuToSheetMapping[selectedMenu]],
        });
      }

      console.log("AI Response received:", response.data.content);

      // Format the AI response for display in the modal
      const formattedResponse = formatAIResponse(response.data.content);
      setAIResponse(formattedResponse);

      setIsLoading(false);
      setIsModalVisible(true);
    } catch (error) {
      console.error("Error analyzing with AI:", error);
      setIsLoading(false);
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const getColumns = () => {
    if (!filteredData || filteredData.length === 0) {
      return [];
    }

    const columns = Object.keys(filteredData[0])
      .map((key) => {
        const isNumeric = typeof filteredData[0][key] === "number";

        if (selectedMenu === "News") {
          if (key === "Title") {
            return {
              title: key,
              dataIndex: key,
              key: key,
              sorter: (a, b) => a[key].localeCompare(b[key]),
              render: (text, record) => (
                <a href={record.Link} target="_blank" rel="noopener noreferrer">
                  {text}
                </a>
              ),
            };
          } else if (key === "Date") {
            return {
              title: key,
              dataIndex: key,
              key: key,
              sorter: (a, b) => new Date(a[key]) - new Date(b[key]),
              render: (text) => {
                if (isNaN(text)) {
                  return text;
                } else {
                  const date = new Date((text - (25567 + 1)) * 86400 * 1000);
                  return date.toLocaleDateString();
                }
              },
            };
          } else if (key === "Thumbnail" || key === "Link") {
            return null;
          }
        }

        if (selectedMenu === "Employee Feedback") {
          if (key === "Review Title") {
            return {
              title: key,
              dataIndex: key,
              key: key,
              sorter: (a, b) => a[key].localeCompare(b[key]),
              render: (text, record) => (
                <a
                  href={record["Review Link"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {text}
                </a>
              ),
            };
          } else if (key === "Review Date") {
            return {
              title: key,
              dataIndex: key,
              key: key,
              sorter: (a, b) => new Date(a[key]) - new Date(b[key]),
              render: (text) => {
                if (isNaN(text)) {
                  return text;
                } else {
                  const date = new Date((text - (25567 + 1)) * 86400 * 1000);
                  return date.toLocaleDateString();
                }
              },
            };
          } else if (key === "Review Link") {
            return null;
          }
        }

        if (selectedMenu === "Events") {
          if (key === "Event") {
            return {
              title: key,
              dataIndex: key,
              key: key,
              sorter: (a, b) => a[key].localeCompare(b[key]),
              render: (text, record) =>
                record.Link && record.Link !== "Not specified" ? (
                  <a
                    href={record.Link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {text}
                  </a>
                ) : (
                  text
                ),
            };
          } else if (key === "Date") {
            return {
              title: key,
              dataIndex: key,
              key: key,
              sorter: (a, b) => new Date(a[key]) - new Date(b[key]),
              render: (text) => {
                if (text === "Not specified" || isNaN(text)) {
                  return text;
                } else {
                  const date = new Date((text - (25567 + 1)) * 86400 * 1000);
                  return date.toLocaleDateString();
                }
              },
            };
          } else if (key === "Link") {
            return null;
          }
        }

        if (selectedMenu === "Clients") {
          if (key === "Client") {
            return {
              title: key,
              dataIndex: key,
              key: key,
              sorter: (a, b) => a[key].localeCompare(b[key]),
              render: (text, record) =>
                record.Link && record.Link !== "Not specified" ? (
                  <a
                    href={record.Link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {text}
                  </a>
                ) : (
                  text
                ),
            };
          }
        }

        // Handle General Information Tab Formatting
        if (selectedMenu === "General Information") {
          if (key === "_id" || key === "customers") {
            return null;
          }

          const formattedKey = key
            .replace(/_/g, " ")
            .replace(/\b\w/g, (char) => char.toUpperCase());

          let renderFunction = (text) => text;
          if (key === "annual_recurring_revenue") {
            renderFunction = (text) =>
              `$ ${text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
          } else if (key === "number_of_linkedin_followers") {
            renderFunction = (text) =>
              text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          } else if (isNumeric && key !== "year_founded") {
            renderFunction = (text) =>
              text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }

          return {
            title: formattedKey,
            dataIndex: key,
            key: key,
            sorter: (a, b) =>
              isNumeric ? a[key] - b[key] : a[key].localeCompare(b[key]),
            render: renderFunction,
          };
        }

        return {
          title: key,
          dataIndex: key,
          key: key,
          sorter: (a, b) =>
            isNumeric ? a[key] - b[key] : a[key].localeCompare(b[key]),
          filters: [...new Set(filteredData.map((item) => item[key]))].map(
            (value) => ({
              text: value,
              value: value,
            })
          ),
          onFilter: (value, record) => record[key] === value,
          render: (text) => (isNumeric ? formatNumber(text) : text),
        };
      })
      .filter((column) => column !== null);

    const companyIndex = columns.findIndex(
      (col) => col.dataIndex === "Company"
    );
    if (companyIndex > 0) {
      const companyColumn = columns.splice(companyIndex, 1)[0];
      columns.unshift(companyColumn);
    }

    return columns;
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsible
        style={{ backgroundColor: "#001529", paddingTop: "64px" }}
      >
        <Menu
          theme="dark"
          mode="inline"
          onSelect={handleMenuSelection}
          defaultSelectedKeys={["Dashboard Charts"]}
        >
          <Menu.Item key="Dashboard Charts" icon={<DashboardOutlined />}>
            Dashboard Charts
          </Menu.Item>
          <Menu.Item key="General Information" icon={<TeamOutlined />}>
            General Information
          </Menu.Item>
          <Menu.Item key="News" icon={<BarChartOutlined />}>
            News
          </Menu.Item>
          <Menu.Item key="Linkedin" icon={<LinkedinOutlined />}>
            Linkedin
          </Menu.Item>
          <Menu.Item key="Functionality" icon={<BarChartOutlined />}>
            Functionality
          </Menu.Item>
          <Menu.Item key="Events" icon={<CalendarOutlined />}>
            Events
          </Menu.Item>
          <Menu.Item key="Video Analysis" icon={<VideoCameraOutlined />}>
            Video Analysis
          </Menu.Item>
          <Menu.Item key="Employee Feedback" icon={<SmileOutlined />}>
            Employee Feedback
          </Menu.Item>
          <Menu.Item key="Reviews" icon={<PieChartOutlined />}>
            Reviews
          </Menu.Item>
          <Menu.Item key="Strategy" icon={<LineChartOutlined />}>
            Strategy
          </Menu.Item>
          <Menu.Item key="Google Ads" icon={<DollarOutlined />}>
            Google Ads
          </Menu.Item>
          <Menu.Item key="Pricing" icon={<FundOutlined />}>
            Pricing
          </Menu.Item>
          <Menu.Item key="Jobs" icon={<SolutionOutlined />}>
            Jobs
          </Menu.Item>
          <Menu.Item key="Clients" icon={<UserOutlined />}>
            Clients
          </Menu.Item>
          <Menu.Item key="Ask AI" icon={<RobotOutlined />}>
            Ask AI
          </Menu.Item>
          <Menu.Item key="Battlecards" icon={<DashboardOutlined />}>
            Battlecards
          </Menu.Item>
        </Menu>
      </Sider>

      <Layout>
        <Header
          className="site-layout-background"
          style={{
            padding: "0 16px",
            display: "flex",
            alignItems: "center",
            color: "white",
          }}
        >
          <img
            src={logo}
            alt="BattleCards Logo"
            style={{ maxHeight: "32px", marginRight: "16px" }}
          />
          <h1 style={{ color: "white", margin: 0 }}>
            BattleCards - Competitor Analysis Platform - Prototype
          </h1>
          {selectedMenu !== "Ask AI" && (
            <Button
              type="primary"
              icon={<RobotOutlined />}
              style={{ marginLeft: "16px" }}
              onClick={handleAIAnalysis}
            >
              Analyze with AI
            </Button>
          )}
        </Header>

        <Content style={{ margin: "16px", position: "relative" }}>
          {isLoading && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 9999,
              }}
            >
              <Spin size="large" />
            </div>
          )}

          {selectedMenu === "Ask AI" ? (
            <AskAI />
          ) : selectedMenu === "Video Analysis" ? (
            <div>
              {renderVideoAnalysis()}
              <Pagination
                current={currentPage}
                pageSize={ITEMS_PER_PAGE} // This now reflects the correct 10 items per page
                total={video_analysis.length}
                onChange={(page) => {
                  const paginatedData = video_analysis.slice(
                    (page - 1) * ITEMS_PER_PAGE,
                    page * ITEMS_PER_PAGE
                  );
                  setPaginatedData(paginatedData);
                  setCurrentPage(page);
                }}
                style={{ marginTop: "16px", textAlign: "center" }}
              />
            </div>
          ) : selectedMenu === 'Pricing' ? (
  <>
    {renderPricing()}
    <Pagination
      current={currentPage}
      pageSize={ITEMS_PER_PAGE}
      total={filteredData.length}
      onChange={(page) => {
        const paginatedData = filteredData.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE);
        setPaginatedData(paginatedData);
        setCurrentPage(page);
      }}
      style={{ marginTop: '16px', textAlign: 'center' }}
    />
  </>
) :selectedMenu === "Employee Feedback" ? (
            <Row gutter={[16, 16]}>{renderEmployeeFeedbacks()}</Row>
          ) : selectedMenu === "Google Ads" ? (
            <>
              {renderGoogleAds()}
              <Pagination
                current={currentPage}
                pageSize={ITEMS_PER_PAGE}
                total={filteredData.length}
                onChange={(page) => {
                  const paginatedData = filteredData.slice(
                    (page - 1) * ITEMS_PER_PAGE,
                    page * ITEMS_PER_PAGE
                  );
                  setPaginatedData(paginatedData);
                  setCurrentPage(page);
                }}
                style={{ marginTop: "16px", textAlign: "center" }}
              />
            </>
          ) : selectedMenu === "News" ? (
            <Row gutter={[16, 16]}>
              {renderNews()}
              <Pagination
                current={currentPage}
                pageSize={ITEMS_PER_PAGE}
                total={news.length}
                onChange={(page) => setCurrentPage(page)}
                style={{ marginTop: "16px", textAlign: "center" }}
              />
            </Row>
          ) : selectedMenu === "Dashboard Charts" ? (
            <DashboardCharts data={data} />
          ) : selectedMenu === "Battlecards" ? (
            <div className="custom-carousel">
              <Carousel
                ref={carouselRef}
                arrows
                prevArrow={<CustomPrevArrow />}
                nextArrow={<CustomNextArrow />}
              >
                {companyData.map(renderSlide)}
              </Carousel>
            </div>
          ) : selectedMenu === "Linkedin" ? (
            <div>
              <Pagination
                style={{ marginBottom: 16 }}
                current={current}
                total={filteredData.length}
                pageSize={5}
                onChange={(page, pageSize) => {
                  console.log("Navigating to page:", page);
                  console.log(
                    "Currently filtered companies:",
                    filteredCompanies
                  );

                  const paginatedData = filteredData.slice(
                    (page - 1) * pageSize,
                    page * pageSize
                  );
                  setPaginatedData(paginatedData);
                  setCurrent(page);
                }}
              />
              {renderCompanyFilter()}
              {renderLinkedinPosts()}
              <Pagination
                style={{ marginTop: 16 }}
                current={current} // This ensures the pagination component reflects the current page
                total={filteredData.length}
                pageSize={5}
                onChange={(page, pageSize) => {
                  console.log("Navigating to page:", page);
                  console.log(
                    "Currently filtered companies:",
                    filteredCompanies
                  );

                  const paginatedData = filteredData.slice(
                    (page - 1) * pageSize,
                    page * pageSize
                  );
                  setPaginatedData(paginatedData); // Update the paginated data
                  setCurrent(page); // Update the current page state
                }}
              />
            </div>
          ) : selectedMenu === "Reviews" ? (
            <>
              {renderReviews()}
              <Pagination
                current={currentPage}
                pageSize={ITEMS_PER_PAGE}
                total={filteredData.length}
                onChange={(page) => {
                  const paginatedData = filteredData.slice(
                    (page - 1) * ITEMS_PER_PAGE,
                    page * ITEMS_PER_PAGE
                  );
                  setPaginatedData(paginatedData);
                  setCurrentPage(page);
                }}
                style={{ marginTop: "16px", textAlign: "center" }}
              />
            </>
          ) : selectedMenu === "Events" ? ( // Add this condition for Events
            <Row gutter={[16, 16]}>{renderEvents()}</Row>
          ) : selectedMenu === "Functionality" ? ( // Add this condition for Functionality
            <Row gutter={[16, 16]}>{renderFunctionality()}</Row>
          ) : (
            filteredData && (
              <Table
                dataSource={filteredData}
                columns={getColumns()}
                rowKey={(record, index) => index}
                onChange={handleTableChange}
              />
            )
          )}
        </Content>

        <Modal
          title={
            <>
              <RobotOutlined style={{ marginRight: 8 }} />
              AI Analysis
            </>
          }
          visible={isModalVisible}
          footer={[
            <Button
              key="copy"
              onClick={() => {
                navigator.clipboard.writeText(aiResponse);
                message.success("AI Analysis copied to clipboard");
              }}
            >
              Copy
            </Button>,
            <Button key="close" type="primary" onClick={handleCancel}>
              Close
            </Button>,
          ]}
          onCancel={handleCancel}
          width={800}
        >
          {isLoading ? (
            <Spin />
          ) : (
            <div
              className="modal-paragraph"
              dangerouslySetInnerHTML={{ __html: aiResponse }}
            />
          )}
        </Modal>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
